<template>
    <b-overlay :show="pageBusy" variant="transparent">
        <b-card>
            <div class="row pt-0">
                <div class="col-12 pb-1">
                    <span class="font-weight-bolder">Filters</span>
                </div>
                <div class="col-lg-3 col-12 col-md-6">
                    <label>Date Range</label>
                    <flat-pickr
                        v-model="range"
                        class="form-control"
                        :config="{ mode: 'range'}"
                        />
                </div>
                <div class="col-lg-3 col-12 col-md-6">
                    <label>Status</label>
                    <v-select  v-model="status" :reduce="e=>e.value" :searchable="false" :options="statusOptions" :clearable="false"></v-select>
                </div>
                <div class="col-lg-3 col-12 col-md-6">
                    <label>Account type</label>
                    <v-select v-model="userType" :reduce="e=>e.value" :searchable="false" :options="userTypeOptions" :clearable="false"></v-select>
                </div>
                <div class="col-lg-3 col-12 col-md-6">
                    <label>User Name</label>
                    <input v-model="userName" type="text" class="form-control" placeholder="e.g. John Doe">
                </div>
                <div class="col-lg-3 col-12 col-md-6">
                    <label>User ID</label>
                    <input v-model="userId" type="text" class="form-control" placeholder="">
                </div>
                <div class="col-lg-3 col-12 col-md-6">
                    <label>Short ID</label>
                    <input v-model="ufId" type="text" class="form-control" placeholder="">
                </div>
                <div class="col-lg-3 col-12 col-md-6">
                    <label>Referral ID</label>
                    <input v-model="referralId" type="text" class="form-control" placeholder="">
                </div>
                <div class="col-lg-2 col-12 col-md-6 pt-2 pt-lg-2" @click="filterFeedback()">
                    <span class="btn btn-primary"> <feather-icon icon="SearchIcon" /> Search</span>
                </div>
            </div>
        </b-card>
        <b-card>
            <b-overlay :show="itemBusy">
                <b-table
                    class="rounded border"
                    :items="items"
                    :fields="fields"
                    :per-page="10"
                    :current-page="1"
                    responsive
                >

                    <template #head()="scope">
                        <div class="text-nowrap text-primary">
                        {{ scope.label }}
                        </div>
                    </template>
                    <template #cell(userName)="data">
                        <span class="text-nowrap" v-if="data.item.userId">
                            <div class="d-flex align-items-center">
                                <div class="pr-1">
                                    <b-avatar variant="secondary"></b-avatar>
                                </div>
                                <div class="w-100">
                                    {{data.value}}
                                </div>
                            </div>
                        </span>
                    </template>
                    <template #cell(userType)="data">
                        <span :class="`text-nowrap badge ${data.value == 1 ? 'badge-primary' : 'badge-dark'}`" v-if="data.item.userId">
                            {{(data.value == 1)? 'Creator Account':  'Backer Account'}}
                        </span>
                    </template>
                    <template #cell(ufId)="data">
                        <div v-if="data.item.ufId">
                            <div class="text-nowrap">
                                User ID: <span class="font-weight-bolder text-dark">{{ data.item.userId }}</span> 
                            </div>
                            <div class="text-nowrap">
                                Short ID: <span class="font-weight-bolder text-dark">{{ data.item.ufId }}</span> 
                            </div>
                            <div v-if="data.item.rId" class="text-nowrap">
                                Referred by: <span class="text-primary font-weight-bolder">{{ data.item.rId }}</span> 
                            </div>
                        </div>
                    </template>
                    <template #cell(status)="data">
                        <span class="text-nowrap" v-if="data.item.userId">
                            <b-badge variant="success" v-if="data.item.isEmailVerified">
                                Verified
                            </b-badge>
                            <b-badge variant="danger" v-else>
                                Unverified
                            </b-badge>
                        </span>
                    </template>
                    <template #cell()="data">
                        <div class="text-nowrap pt-1">
                            {{data.value}}
                        </div>
                    </template>
                     <template #cell(name)="data">
                        <span class="text-nowrap" v-if="data.item.userId">
                            <div class="d-flex align-items-center">
                                <div class="pr-1">
                                    <b-avatar variant="secondary"></b-avatar>
                                </div>
                                <div class="w-100">
                                    <div class="font-weight-bolder">
                                        {{data.item.firstName}} {{data.item.lastName}}
                                    </div>
                                    <small>{{data.item.email}}</small>
                                </div>
                            </div>
                        </span>
                    </template>
                    <template #cell(createdAt)="data">
                        <div class="text-nowrap pt-1" v-if="data.item.userId">
                            {{ dayjs(data.value).format("MMM D, YYYY h:mm A") }}
                        </div>
                    </template>
                    <template #cell(updatedAt)="data">
                        <div class="text-nowrap pt-1" v-if="data.item.userId">
                            {{ dayjs(data.value).format("MMM D, YYYY h:mm A") }}
                        </div>
                    </template>
                    <template #cell(actions)="data">
                         <div class="text-nowrap" v-if="data.item.userId">
                             <b-button size="sm" pill variant="outline-primary" :to="`/campaigns/manage?user=${data.item.userId}`">
                            See campaigns
                             </b-button>
                         </div>
                    </template>
                </b-table>
            </b-overlay>
            <b-modal id="view-images" ok-only hide-header size="lg" centered no-close-on-backdrop no-close-on-esc body-bg-variant="dark">
                <div style="min-height: 400px;">
                    <b-carousel id="carousel-main" style="text-shadow: 0px 0px 2px #000" :interval="7000" :controls="images.length > 1" :indicators="images.length>1" img-height="480px">
                        <b-carousel-slide  v-for="(item, index) in images" :key="index" :text="`${item}`" :img-src="item" />
                    </b-carousel>
                </div>
            </b-modal>
            <b-modal id="view-campaign" ok-only title="View Campaign Detail" size="xl" centered no-close-on-backdrop no-close-on-esc>
               <div class="row">
                   <div class="col-12" style="min-height: 600px;">
                       <iframe class="w-100" height="100%" :src="`http://localhost:3000/preview-campaign/data-only/campaign?id=${account._id}`" frameborder="0"></iframe>
                       <!-- <campaign :campaign="account" /> -->
                   </div>
               </div>
            </b-modal>
            <div class="d-flex p-1">
                <div class="w-100">
                    Page <span class="text-primary"> {{currentPage}}</span> of <span class="text-primary">{{Math.ceil(totalRows/10)}}</span>
                </div>
                <div class="w-100">
                    <b-pagination
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="10"
                        aria-controls="users-table"
                        align="right"
                        size="sm"
                        class="my-0"
                        @page-click="loadNewPage"
                    />
                </div>
            </div>
            <b-sidebar
                shadow
                id="validate"
                sidebar-class="sidebar-lg"
                bg-variant="white"
                backdrop
                right
                title="Account Review"
                title-background="primary"
                no-close-on-backdrop
                >
                <template #default="{ hide }">
                    <div>
                        <div class="p-2 border-bottom border-top">
                            <h5 class="font-weight-bolder">Name</h5>
                            <span>{{account.firstName}} {{account.lastName}}</span>
                            <h5 class="font-weight-bolder mt-2">email</h5>
                            <span>{{account.email}}</span>
                            <h5 class="font-weight-bolder mt-2">Phone</h5>
                            <span>{{account.phone}}</span>
                            <h5 class="font-weight-bolder mt-2">Gender</h5>
                            <span>{{genders[account.sex]}}</span>
                            <!-- <h5 class="font-weight-bolder mt-2">User type</h5>
                            <span>
                                <span :class="`text-nowrap badge ${account.userType == 1 ? 'badge-primary' : 'badge-success'}`">
                                    {{(account.userType == 1)? 'Creator Account':  'Backer Account'}}
                                </span>
                            </span> -->
                            <h5 class="font-weight-bolder mt-2">Status</h5>
                            <span>
                                <span class="text-nowrap">
                                    <b-badge :variant="displayStatus[1][account.status]">
                                        {{ displayStatus[0][account.status] }}
                                    </b-badge>
                                </span>
                            </span>
                            <h5 class="font-weight-bolder mt-2">Bio</h5>
                            <span>{{account.bio}}</span>
                            <h5 class="font-weight-bolder mt-2">Gallery</h5>
                            <div>
                                <div class="d-inline-block mr-1" v-for="item in account.galleryImages" :key="item">
                                    <b-img :src="item" rounded height="100px" class="cursor-pointer" @click="triggerViewImage(account.galleryImages)">

                                    </b-img>
                                </div>
                            </div>

                            <div v-if="account.userType == 1">
                                <div v-if="account.profession">
                                    <h5 class="font-weight-bolder mt-2">Profession</h5>
                                    <span>{{account.profession}}</span>
                                </div>
                                <div v-if="account.address">
                                    <h5 class="font-weight-bolder mt-2">Address</h5>
                                    <span>{{account.address}}</span>
                                </div>
                                <div v-if="account.companyDomain">
                                    <h5 class="font-weight-bolder mt-2">Company Domain</h5>
                                    <span>{{account.companyDomain}}</span>
                                </div>
                                <div v-if="account.companyName">
                                    <h5 class="font-weight-bolder mt-2">Company Name</h5>
                                    <span>{{account.companyName}}</span>
                                </div>
                                <h5 class="font-weight-bolder mt-1">
                                    Legal Documents
                                </h5>
                                <b-list-group>
                                    <b-list-group-item v-for="(i,j) in account.images" :key="j">
                                        <a :href="i + '?' +ftoken" target="_blank" class="d-block cursor-pointer">
                                            <b-img fluid rounded @click="triggerViewImage([i + '?' + ftoken ])" :src="i + '?' +ftoken" v-if="i.includes('.png') || i.includes('.gif') || i.includes('.jpeg') || i.includes('.jpg')"></b-img>
                                            <small v-else class="text-muted text-nowrap d-block mt-1 text-truncate">
                                                {{i + '?' +ftoken}}
                                            </small>
                                        </a>
                                    </b-list-group-item>
                                </b-list-group>
                            </div>

                            <div v-if="account.status !=5 && account.status !=-5">
                                <h4 class="text-primary mt-2 pt-2 border-top">Add review</h4>
                                <div class="d-flex justify-content-between">
                                    <b-form-radio v-model="applicationStatus" value="1">
                                        Approve
                                    </b-form-radio>
                                    <b-form-radio v-model="applicationStatus" value="2">
                                        Decline
                                    </b-form-radio>
                                </div>
                                <div v-if="applicationStatus == 2" class="mt-1">
                                    <label class=""> Status </label>
                                    <v-select  v-model="user.status"  :options="feedbackOptions"></v-select>
                                    <div>
                                        <label class="mt-2"> Reason </label>
                                        <b-textarea v-model="user.message">
                                        </b-textarea>
                                    </div>
                                </div>
                                <div class="mt-2">
                                    <b-button rounded variant="primary" @click="addReview(hide)" :disabled="!applicationStatus || (applicationStatus == 2 && (!user.status.value || (user.status.value != 5 && !user.message.trim().length)))">
                                        Submit
                                    </b-button>
                                </div>
                            </div>
                        </div>
                        <h4 class="p-2 text-primary" v-if="account.reviewHistory && account.reviewHistory.length">Recent Rejections</h4>
                        <b-list-group>
                            <b-list-group-item v-for="(review, j) in account.reviewHistory" :key="j">
                                <div class="alert alert-secondary rounded p-1 mb-0">
                                    <div>
                                        <b-badge :variant="displayStatus[1][review.status]">
                                            {{ displayStatus[0][review.status] }}
                                        </b-badge>
                                    </div>
                                    {{review.message? review.message : 'No display name'}}
                                </div>
                                <div class="d-flex p-0">
                                    <div class="w-100">
                                        <small>{{review.reviewer}}</small>
                                    </div>
                                    <div class="text-right text-nowrap">
                                        <small class="text-primary">
                                            {{ dayjs(review.time).format("MMM D, YYYY h:mm A") }}
                                        </small>
                                    </div>
                                </div>
                            </b-list-group-item>
                        </b-list-group>
                    </div>
                </template>
            </b-sidebar>
        </b-card>
    </b-overlay>


</template>

<script>

import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import dayjs from "dayjs";
import flatPickr from 'vue-flatpickr-component'
import Utils from '@/utils/index';
import Vue from "vue";
import API from './api'

const vm = new Vue({});
const toast = useToast()
export default {
    name: 'Feedback',
    components: {
        flatPickr,
        vSelect,
    },
    data() {
        return {
            ftoken: '',
            userId: '',
            referralId: '',
            ufId: '',
            dayjs: dayjs,
            currentPage: 1,
            totalRows: 10,
            range: '',
            reviews: [],
            searchDate: '',
            dateRange: '',
            userName: '',
            status: null,
            displayStatus:[
                {
                    '1':'Pending', '5': 'Approved', '-1':'Rejected', '-5': 'Rejected Permanently', '-2': 'Invalid Documents'
                },
                {
                    '1':'secondary', '5': 'success', '-1':'warning', '-5': 'danger', '-2': 'warning'
                },
            ],
            statusOptions: [
                {label:'All',value: null},
                {label:'Verified',"value":true},
                {label:'Unverified',"value": false},
            ],
            userType: '',
            userTypeOptions: [
                {label:'All',value: ''},
                {label:'Backer',value: 2},
                {label:'Creator',value: 1}
            ],
            user: {
                message:'',
                status: '',
                id: ''
            },
            account: {
                reviewHistory: []
            },
            checked: '',
            images: [],
            feedbackOptions: [
                // {label:'Approved',"value":5},
                {label:'Rejected',"value":-1},
                {label:'Invalid Documents',"value":-2},
                {label:'Rejected Permanently',"value":-5},
                ],
            items: [],
            fields: [
                {key: 'actions', label: 'Actions'},
                {key: 'name', label: 'Name'},
                {key: 'phone', label: 'Phone'},
                {key: 'userType', label: 'User Type'},
                // {key: 'bio', label: 'bio'},
                {key: 'status', label: 'Status'},
                {key: 'ufId', label: 'ID'},
                {key: 'createdAt', label: 'Created At'},
                {key: 'updatedAt', label: 'Updated At'},
            ],
            genders: {
                '0':'Rather not say',
                '1':'Male',
                '2':'Female',
                '3':'Others',
            },
            searchParams: {},
            itemBusy: false,
            pageBusy: false,
            applicationStatus: 0,
        }
    },
    watch: {
        range(newRange, oldRange) {
            newRange = newRange.split(' to ');
            if(newRange.length === 2) {
                this.searchDate = {
                    from: newRange[0],
                    to: newRange[1]
                }
            }else if(newRange[0].length == 10) {
                 this.searchDate = {
                    from: newRange[0],
                    to: newRange[0]
                }
            }else {
                this.searchDate = '';
            }
        },
        applicationStatus(data) {
            if(data == 1) {
                this.user.status = {
                    value: 5,
                    label: 'Approved',
                }
                this.user.message = '';
            }else{
                this.user.status = '';
            }
        }
    },
    created() {
        const  { user } = this.$route.query;
        if(user){
            this.userId = user;
        }
        this.items = Utils.completeTable();
        this.filterFeedback();
    },
    mounted() {
        this.ftoken = vm.$fileAccessToKen;
    },
    methods: {
        showCampaign() {
            this.$bvModal.show('view-campaign');
        },
        triggerViewImage(image = []) {
            this.images = image;
            this.$bvModal.show('view-images');
        },
        addReview(e) {
            API.addReview({message: this.user.message.trim(), status: this.user.status.value, id: this.user.id}).then(res=>{
                if(res.success && res.data) {
                    this.user.status = {};
                    this.user.message = '';
                    this.items = this.items.map(item=>{
                        if(item._id == res.data.application._id) {
                            item = res.data.application;
                        }
                        return item;
                    });
                    e();
                    this.showToast('Review Added successfully','CheckCircle','success');
                }
            }).catch(err=>{
                console.log(err);
            })
        },
        triggerProcessing(data) {
            this.account = data;
            this.user.id = data._id;
        },
        loadNewPage(e,page) {
            console.log(page)
            e.preventDefault();
            this.getApplications(page);
        },
        getApplications(page = 1) {
            // this.pageBusy = true;
            API.getApplications({...this.searchParams, pageNum: page}).then(res => {
                this.pageBusy = false;
                if(res.success && res.data) {
                    this.currentPage = page;
                    this.items = Utils.completeTable(res.data.list);
                    this.totalRows = res.data.total || 10;
                }
            }).catch(() => {
                this.pageBusy = false;
                this.showToast();
            })
        },
         filterFeedback() {
            
            this.searchParams = {};
            if(this.userName) {
                this.searchParams.name = this.userName;
            }
            if(this.userId) {
                this.searchParams.userId = this.userId;
            }
            if(this.userId) {
                this.searchParams.userId = this.userId;
            }
            if(this.ufId) {
                this.searchParams.ufId = this.ufId;
            }
            if(this.referralId) {
                this.searchParams.rId = this.referralId;
            }
            if(this.status !== null) {
                this.searchParams.isEmailVerified = this.status
            }
            if(this.userType) {
                this.searchParams.userType = this.userType
            }
            if(this.searchDate && this.searchDate.from) {
                this.searchParams.startDate = this.searchDate.from + ' 00:00:00';
                if(!this.searchDate.to) {
                    this.this.searchDate.to = this.searchDate.from
                }
                this.searchParams.endDate = this.searchDate.to + ' 23:59:59';
            }
            
            this.getApplications(1);
            this.currentPage = 1;
        },
        showToast(message, icon, variant) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: message || 'Error fetching applications',
                    icon: icon || 'InfoIcon',
                    variant: variant || 'danger',
                },
            })
        },
        showModal(feedback) {
            this.$bvModal.msgBoxOk(feedback, {
            title: 'Feedback',
            size: 'md',
            buttonSize: 'md',
            okVariant: 'primary',
            headerClass: 'p-2 border-bottom-0',
            bodyClass: 'text-break',
            footerClass: 'p-2 border-top-0',
            centered: true
            })
            .then(value => {
                this.feedback = value
            })
            .catch(err => {
                // An error occurred
            })
        },
        markAsProcessed(feedbackId) {
            if(feedbackId) {
                this.feedbacks = {};
                // this.pageBusy = true;
                API.markAsProcessed({_id: feedbackId}).then(res => {
                    this.pageBusy = false;
                    if(res.success) {
                        this.items = this.items.map(item =>{
                            if(item._id == feedbackId) {
                                item.status = res.data.status;
                            }
                            return item;
                        })
                        this.showToast('Feedback Marked as processed', 'CheckIcon', 'success');
                    }
                }).catch(() => {
                    this.pageBusy = false;
                    this.showToast('Error processing feedback')
                })
            }
        }
    }
}
</script>

<style lang="scss" scope>

    @import '@core/scss/vue/libs/vue-select.scss';
    @import '@core/scss/vue/libs/vue-flatpicker.scss';

    .feedback-content{

        max-width: 100px !important;
    }
    .feedback-content:hover{
        transform: scale(1.2);
    }
    #carousel-main{
        text-align: center !important;
        img{
            height: 480px !important;
            width: auto !important;
        }
    }
</style>